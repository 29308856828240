/**
 * Site header
 */
.site-header {
    border-top: 0px solid $grey-color-dark;
	border-bottom: 4px solid $clemson-purple;
    min-height: 56px;
    background: cadetblue; //$clemson-orange;
    // Positioning context for the mobile navigation icon
    position: relative;

}

.site-title {
    font-size: 26px;
/* 		margin-top: 15px;
    line-height: 40px; */
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
    font-weight: 500;
		font-family: 'Titillium Web', sans-serif;
    color: #ffffff;
    &,
    &:visited {
        color: #ffffff;
    }
    &,
    &:hover {
	text-decoration: none;
}
}

.site-header .wrapper {
	padding-top: 20px;
	position: relative;
}

.site-nav ul li a {
	color: #fff;
}

.site-nav ul {

	text-align: right;
	float: right;
	padding-top: 9px;
	font-size: 85%;
	letter-spacing: 1px;
	line-height: 1em;
	font-weight: 600;
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-transform: uppercase;
		font-family: 'Titillium Web', sans-serif;

}

.site-nav .menu > li {
	border-bottom: 3px solid transparent;
	display: inline-block;
	height: 23px;
	line-height: normal;
	margin: 12px 0 0 20px;
	padding: 0 0 2px 0;
	position: relative;
	vertical-align: top;
}

.site-nav a {
	display: block;
        color: #ffffff;
}

/* Sub Menu */

.site-nav .sub-menu a {
	display: block;
	font-weight: normal;
	height: auto;
	letter-spacing: 0;
	line-height: 1.2em;
	padding: 4px 10px;
color: #522d80;

}

.site-nav .sub-menu a:hover {
    color: cadetblue;//$clemson-orange;
}
.site-nav  .sub-menu {
	background: #ffffff;
	border-color: $clemson-purple;
	min-width: 265px;

	z-index:2147483647;
}
.site-nav .sub-menu li.current-menu-item > a {
	border-left-color: $clemson-orange;
	border-right-color: $clemson-orange;
	border-right: 0px;

}


.sub-menu {
	border: 3px solid transparent;
	border-top: 0;
	/* bg and border colors set in header */
	box-shadow: 0 2px 2px rgba(0,0,0,0.4);
	-moz-box-shadow: 0 2px 2px rgba(0,0,0,0.4);
	-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.4);
	display: none;
	right: -10px;
	padding: 4px 0 3px 0;
	position: absolute;
	text-align: left;
	text-transform: none;
	top: 28px;
	min-width: 265px;

z-index:999;
	font-family: Helvetica, Arial, Verdana, sans-serif;

}

.sub-menu li {
	border-bottom: 0;
	display: block;
	height: auto;
	margin: 3px 0;
	padding: 0;
	text-align: left;
}

.site-nav li:hover > .sub-menu {
	display: block;
}



.site-nav h1 {
	position: absolute;
	left: -999em;
}


.site-nav {
    float: right;
    line-height: 40px;


    .menu-icon {
        display: none;
    }

    .page-link {
        color: #ffffff;
    background: cadetblue;//$clemson-orange;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }



    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}


